import { storeGloveActiveTests, initGloveTestListener } from "./glove-test-module";
import { initGloveEventListener } from "./glove-event-module";
import { isServerSide } from "/utils/general";
const Sentry = require("@sentry/nextjs");
let gloveEnvironmentInitialized = false;
function setupGlove(abTests = [], simulateGloveActiveABTests = []) {
    if (!isServerSide() && !gloveEnvironmentInitialized) {
        try {
            storeGloveActiveTests(abTests, simulateGloveActiveABTests);
            initGloveTestListener();
            initGloveEventListener();
            gloveEnvironmentInitialized = true;
        } catch (error) {
            Sentry.captureException(error, {
                message: "Error setting up Glove environment"
            });
            return false;
        }
    }
    return gloveEnvironmentInitialized;
}
export default setupGlove;
