import styled from "@emotion/styled";
export const StyledBookAMeetingLoadingScreen = styled.div`
  .loading-screen-image {
      max-width: 348px;
      max-height: 240px;
  }
    
  .loading-screen-text {
      font-size: 1.5rem;
      font-weight: 500;
  }
`;
