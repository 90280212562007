import { sendAbTestEvent } from "services/new-ab-tests/new-ab-tests-service";
const Sentry = require("@sentry/nextjs");
const GLOVE_TEST_PREFIX = "glove_test_";
const GLOVE_TEST_MESSAGE_TYPE = "glove_test";
const SUPPORTED_VARIANTS = [
    "control",
    "variant_a",
    "variant_b"
];
const isGloveTest = (test)=>{
    var _test_name;
    var _test_name_startsWith;
    return (_test_name_startsWith = test === null || test === void 0 ? void 0 : (_test_name = test.name) === null || _test_name === void 0 ? void 0 : _test_name.startsWith(GLOVE_TEST_PREFIX)) !== null && _test_name_startsWith !== void 0 ? _test_name_startsWith : false;
};
const getGloveTests = (abTests = [])=>abTests.filter(isGloveTest);
const storeGloveActiveTests = (abTests = [], simulateGloveActiveABTests = [])=>{
    const allTests = [
        ...abTests,
        ...simulateGloveActiveABTests
    ];
    window.activeGloveTests = getGloveTests(allTests).map((test)=>test.name);
};
const validateGloveTestDetails = (details, activeTests)=>{
    const { test_name, test_variant } = details;
    if (!test_name) return {
        valid: false,
        error: "Missing test_name in Glove test trigger"
    };
    if (!test_variant) return {
        valid: false,
        error: `Glove test ${test_name} is missing a test_variant`
    };
    if (!activeTests.includes(test_name)) return {
        valid: false,
        error: `Glove test ${test_name} is not active`
    };
    if (!SUPPORTED_VARIANTS.includes(test_variant)) return {
        valid: false,
        error: `Glove test ${test_name} with variant ${test_variant} is not supported`
    };
    return {
        valid: true
    };
};
const initGloveTestListener = ()=>{
    function validateAndSendTestEvent(details) {
        var _window_activeGloveTests;
        const activeTests = (_window_activeGloveTests = window.activeGloveTests) !== null && _window_activeGloveTests !== void 0 ? _window_activeGloveTests : [];
        const validationResult = validateGloveTestDetails(details, activeTests);
        if (!validationResult.valid) {
            return Sentry.captureMessage(validationResult.error, {
                extra: details
            });
        }
        sendAbTestEvent(details.test_name, details.test_variant);
    }
    window.removeEventListener("message", validateAndSendTestEvent);
    window.addEventListener("message", (event)=>{
        var _event_data;
        if (((_event_data = event.data) === null || _event_data === void 0 ? void 0 : _event_data.type) !== GLOVE_TEST_MESSAGE_TYPE) return;
        if (event.origin !== window.location.origin) {
            return Sentry.captureMessage(`Untrusted message origin for Glove test trigger: ${event.origin}`);
        }
        if (!event.data.details) {
            return Sentry.captureMessage("Missing details in Glove test trigger", {
                extra: event.data
            });
        }
        validateAndSendTestEvent(event.data.details);
    });
};
export { storeGloveActiveTests, initGloveTestListener };
