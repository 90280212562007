import { trackEvent } from "/services/bigbrain-service";
const Sentry = require("@sentry/nextjs");
const GLOVE_EVENT_NAME_PREFIX = "glove_";
const GLOVE_EVENT_MESSAGE_TYPE = "glove_event";
const formatGloveEventName = (eventName)=>`${GLOVE_EVENT_NAME_PREFIX}${eventName}`;
const validateGloveEventDetails = (details)=>{
    const { event_name } = details;
    if (!event_name) return {
        valid: false,
        error: "Missing event_name in Glove event details"
    };
    return {
        valid: true
    };
};
const sendGloveEvent = (details)=>{
    const validationResult = validateGloveEventDetails(details);
    if (!validationResult.valid) {
        return Sentry.captureMessage(validationResult.error, {
            extra: details
        });
    }
    try {
        const formattedEventName = formatGloveEventName(details.event_name);
        trackEvent(formattedEventName, {
            data: details.data
        });
    } catch (error) {
        Sentry.captureException(error, {
            extra: {
                event_name: details.event_name,
                data: details.data,
                message: "Error processing Glove event"
            }
        });
    }
};
const initGloveEventListener = ()=>{
    window.removeEventListener("message", sendGloveEvent);
    window.addEventListener("message", (event)=>{
        var _event_data;
        if (((_event_data = event.data) === null || _event_data === void 0 ? void 0 : _event_data.type) !== GLOVE_EVENT_MESSAGE_TYPE) return;
        if (event.origin !== window.location.origin) {
            return Sentry.captureMessage(`Untrusted message origin for Glove event: ${event.origin}`);
        }
        if (!event.data.details) {
            return Sentry.captureMessage("Missing Glove event details", {
                extra: event.data
            });
        }
        sendGloveEvent(event.data.details);
    });
};
export { initGloveEventListener };
