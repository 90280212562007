import styled from "@emotion/styled";
import { ALWAYS_ON_TOP } from "../../../constants/z-index";
import { GHOST_WHITE_COLOR_NAME } from "../../../styles/color-consts";
import Colors from "../../../styles/colors";
export const StyledLoadingOverlayComponent = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: ${Colors[GHOST_WHITE_COLOR_NAME]};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.125rem;
    Z-index: ${ALWAYS_ON_TOP};
    text-align: center;
`;
